.day {
    margin: 0 4px;
    padding: 4px;
    height: 136px;
    overflow-y: auto;
}

.tdHack td {
    height: 1px;
}

.day:hover {
    background: rgba(0, 0, 0, 0.04);
}

.small .day {
    min-height: 96px;
}
