.card {
    box-shadow: none;
    border-radius: 4px;
    height: 150px;
    width: 150px;
    cursor: pointer;
    border: 1px solid white;
    transition: all .25s ease-in-out;
    text-align: center;
}

.cardInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.cardInner > div:first-of-type {
    font-size: 48px;
}

.cardInner > div:last-of-type {
    font-size: 16px;
    font-weight: bold;
}

.card:hover {
    border-color: #1890ff;
    transform: scale(1.05);
}
