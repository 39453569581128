.listItem {
    cursor: pointer;
}

.listItem:hover {
    background-color: #f5f5f5;
}

.isRead {
    cursor: pointer;
    opacity: 0.5;
}

.isRead:hover {
    background-color: #f5f5f5;
}
